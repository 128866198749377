/* eslint-disable react/no-unescaped-entities */

import React, { MouseEvent, useEffect } from 'react';

import { GetStaticPropsContext, GetStaticPropsResult } from 'next';
import { SSRConfig, withTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';

import { LayoutProps } from '@/c/components/templates/types';
import { DEFAULT_LOCALE } from '@/c/constants/locales';
import { scopedT } from '@/c/translations/utils';

import { NextPageProps } from '../types/NextPageProps';
import withClientAllowForGuestOnly from '../utils/withClientAllowForGuestOnly';

const T_NAMESPACE = 'index-page';

const Page = ({ t }: NextPageProps) => {
    const nt = scopedT(t, T_NAMESPACE);

    useEffect(() => {
        document.body.setAttribute('data-spy', 'scroll');
        document.body.setAttribute('data-target', '.menu');
        document.body.setAttribute('data-offset', '300');

        landingPageOnLoadScript();

        return () => {
            document.body.removeAttribute('data-spy');
            document.body.removeAttribute('data-target');
            document.body.removeAttribute('data-offset');
        };
    }, []);

    return (
        <>
            <Head>
                <title>{nt('pageTitle')}</title>
                <meta name="keywords" content={nt('metaKeywords')} />
                <link href="tassets/css/bootstrap.min.css" rel="stylesheet" type="text/css" />
                <link href="tassets/css/fontawesome.min.css" rel="stylesheet" type="text/css" />
                <link href="tassets/css/helper.css" rel="stylesheet" type="text/css" />
                <link href="tassets/css/style.css" rel="stylesheet" type="text/css" />
                <link href="tassets/css/responsive.css" rel="stylesheet" type="text/css" />

                <script async src="tassets/js/jquery-3.4.1.min.js" />
                <script async src="tassets/js/plugins.js" />
            </Head>
            <div>
                <div id="preloader">
                    <div className="loader3">
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <header>
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-between">
                            <a href="/" className="logo">
                                <img
                                    src="tassets/img/logo.png"
                                    style={{
                                        width: '90%',
                                    }}
                                    alt=""
                                />
                            </a>
                            <div className="menu_wrapper">
                                <div className="menu">
                                    <ul>
                                        <li className="nav-item">
                                            <a className="nav-link active" href="/">
                                                Home
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#blog">
                                                Blog
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="contact.html">
                                                Let's do it
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="right_menu d-lg-none">
                                <div className="hamburger-menu">
                                    <span className="line-top"></span>
                                    <span className="line-center"></span>
                                    <span className="line-bottom"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <main className="overflow-hidden">
                    <div className="home_area">
                        <div className="container">
                            <div className="home_content">
                                <h1>
                                    <span>Get in shape</span> from world-class trainers <span>for free.</span>
                                </h1>
                                <p>
                                    Gym or home, weights or not, train with thousands of programs that match your
                                    fitness goals.
                                </p>
                                <a href="contact.html" className="button">
                                    SAVE YOUR FREE SPOT
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="how_its_work">
                        <div className="container">
                            <div className="title">
                                <h2>How it Works</h2>
                                <p>
                                    FITX365 is a free fitness platform packed with all sorts of training disciplines
                                    from trainers from all over the world. Pick up a routine or make a mix of favourites
                                    and watch yourself change non-stop.
                                </p>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <img className="img-fluid" src="tassets/img/how-work.png" alt="" />
                                </div>
                                <div className="col-lg-6">
                                    <div className="how_work_text">
                                        <div className="num">1</div>
                                        <p>
                                            <strong>Start now</strong> with an easy-to-use app &{' '}
                                            <strong>world-class trainers.</strong>
                                        </p>
                                    </div>
                                    <div className="how_work_text">
                                        <div className="num">2</div>
                                        <p>
                                            <strong>Set your goals </strong>and match the workouts with your journey.
                                        </p>
                                    </div>
                                    <div className="how_work_text">
                                        <div className="num">1</div>
                                        <p>
                                            <strong>Practice any fitness discipline</strong> in our rich platform at no
                                            cost.
                                        </p>
                                    </div>
                                    <a href="contact.html" className="button">
                                        SAVE YOUR FREE SPOT
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="qr_scan">
                        <div className="container">
                            <img className="img-fluid" src="tassets/img/qr-scan.png" alt="" />
                        </div>
                    </div>

                    <div className="s_blog_sec">
                        <div className="container">
                            <div className="s_blog_wrap">
                                <h2>"For things to Change, you have to Change"</h2>
                                <span>
                                    by <strong>Jim Rhon</strong>
                                </span>
                                <img className="img-fluid" src="tassets/img/blog.jpg" alt="" />
                                <div className="s_blog_text">
                                    <p>
                                        Ever been stuck in that spot where everything feels like a jumbled mess? Work,
                                        relationships, family, friends – it's like you're in a never-ending maze with no
                                        way out. <strong>It's tough to imagine things ever changing, right?</strong> But
                                        trust me, you're not alone. Everyone hits that wall at some point. And guess
                                        what? You've got the power to break through it.
                                    </p>
                                    <p>
                                        We are here to tell you, We have been there too, just like countless others
                                        around the globe. But you know who's got your back through it all?{' '}
                                        <strong>You.</strong> Yep, the key to unlocking that change you're craving lies
                                        within you.
                                    </p>
                                    <p>
                                        You've got this fire inside you – that spark that says you can make a
                                        difference. Even if it means stirring things up and facing some chaos. You're at
                                        that crossroads where it's either you or staying in the same old rut. And you
                                        know what? It's okay to choose you.
                                    </p>
                                    <p>
                                        When life isn't dancing to your tune, that's your cue to take the stage and
                                        remix it. I get it, it can feel like trying to move a mountain. But here's the
                                        secret: everything seems impossible until you prove it wrong. Start small, take
                                        those little steps that feel almost insignificant. Because guess what? Those
                                        tiny steps become the staircase to the big change you've been dreaming of.
                                    </p>
                                    <p>Don't know where to start, we got your back.</p>
                                    <p>
                                        Nothing is more effective than starting to work out. If you already do, then
                                        push yourself harder. If you're already giving your all in your workouts, then
                                        keep going and apply the same consistency and determination to everything around
                                        you. Working out isn't only about looking good; it also changes your mindset,
                                        builds discipline, and motivates you to achieve more.
                                    </p>
                                    <p>
                                        <strong>
                                            So, go one more. One more try, one more change, one more pushup. Try one
                                            more trainer, one more workout one more song, one more podcast that
                                            motivates you and you'll probably thank me later.
                                        </strong>
                                    </p>
                                </div>
                                <a href="contact.html" className="button">
                                    SAVE YOUR FREE SPOT
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="cta_sec">
                        <div className="container">
                            <div className="cta_content">
                                <span>87 %</span>
                                <h2>
                                    of people doing fitness, quit because they didn't find their ideal fitness
                                    discipline. <br /> <strong>Don't quit. Find what Fits you.</strong>
                                </h2>
                                <a href="contact.html" className="button">
                                    SAVE YOUR FREE SPOT
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="feature_sec">
                        <div className="container">
                            <div className="row ">
                                <div className="col-lg-12 mb_80">
                                    <div className="feature_box">
                                        <h5>Contact your favorite trainers.</h5>
                                        <span>
                                            <i className="fal fa-check"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb_80">
                                    <div className="feature_box">
                                        <h5>Customized content based on your preferences.</h5>
                                        <span>
                                            <i className="fal fa-check"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb_80">
                                    <div className="feature_box">
                                        <h5>Visual muscle targeting</h5>
                                        <span>
                                            <i className="fal fa-check"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-12 mb_80">
                                    <div className="feature_box">
                                        <h5>Save your favourite workout for later.</h5>
                                        <span>
                                            <i className="fal fa-check"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <a href="contact.html" className="button">
                                    SAVE YOUR FREE SPOT
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="blog_sec" id="blog">
                        <div className="container">
                            <div className="title text-center">
                                <h2>Unlock more insights</h2>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 mt_40">
                                    <div className="blog_wrap">
                                        <img className="img-fluid" src="tassets/img/blog1.png" alt="" />
                                        <div className="blog_text">
                                            <h3>
                                                Debunking Common Fitness Myths and Trends on Social Media: Tips for
                                                Reaching Your Goals
                                            </h3>
                                            <p>
                                                How many times have you believed someone on TikTok explaining how
                                                exercises for Spot Reduction actually work?
                                            </p>
                                            <div className="blog_meta">
                                                {/*<a class="cat" href="#">Workout</a>*/}
                                                <span className="date">April 26, 2024</span>
                                                <span>.</span>
                                                <span className="duration">
                                                    <i className="fas fa-clock"></i> 3 min read
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt_40">
                                    <div className="blog_wrap">
                                        <img className="img-fluid" src="tassets/img/blog2.png" alt="" />
                                        <div className="blog_text">
                                            <h3>
                                                5 Common Fitness Mistakes Beginners Make and How to Avoid Them for
                                                Optimal Results
                                            </h3>
                                            <p>
                                                Are you doing these 5 mistakes when you’re working out? How can you
                                                avoid them and make the most out of your fitness journey?
                                            </p>
                                            <div className="blog_meta">
                                                {/*<a className="cat" href="#">Workout</a>*/}
                                                <span className="date">April 26, 2024</span>
                                                <span>.</span>
                                                <span className="duration">
                                                    <i className="fas fa-clock"></i> 3 min read
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                <footer>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="ft_left">
                                    <img src="tassets/img/qr.png" alt="" />
                                    <h2>Scan the QR Code to Join the Waiting List</h2>
                                    <div className="foot_social">
                                        <a
                                            href="https://www.facebook.com/fitx365official/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="fab fa-facebook-square"></i>
                                        </a>
                                        <a
                                            href="https://www.instagram.com/fitx365official/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                        <a
                                            href="https://www.tiktok.com/@fitx365official"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="ft_right">
                                    <img className="img-fluid" src="tassets/img/ft-img.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="row ft_link_row align-items-center">
                            <div className="col-lg-8">
                                <a href="contact.html" className="button">
                                    SAVE YOUR FREE SPOT
                                </a>
                            </div>
                        </div>
                        <div className="copyright">
                            <p>© 2024. All rights reserved.</p>
                        </div>
                    </div>
                </footer>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" className="back-to-top">
                    <i className="fal fa-angle-up"></i>
                </a>
            </div>
        </>
    );
};
Page.getLayoutProps = {
    customMain: true,
    header: false,
} as LayoutProps;

const tNamespaces = ['common', 'header', 'footer', T_NAMESPACE];

export function landingPageOnLoadScript(): void {
    function run($: any) {
        $('#preloader').delay(400).fadeOut();

        //05. sticky header
        function sticky_header() {
            if ($(window).scrollTop() > 10) {
                $('header').addClass('sticky');
            } else {
                $('header').removeClass('sticky');
            }
        }
        sticky_header();
        $(window).on('scroll', function () {
            sticky_header();
        });

        // Show or hide the sticky footer button
        $(window).on('scroll', function (this: never) {
            if ($(this).scrollTop() > 600) {
                $('.back-to-top').fadeIn(200);
            } else {
                $('.back-to-top').fadeOut(200);
            }
        });

        $('.toggle_btn').click(function (e: MouseEvent) {
            e.preventDefault();
            // @ts-ignore
            $(this).siblings('input[type="radio"]').prop('checked', false);
        });

        //Animate the scroll to yop
        $('.back-to-top').on('click', function (event: MouseEvent) {
            event.preventDefault();

            $('html, body').animate(
                {
                    scrollTop: 0,
                },
                900
            );
        });

        // Hamburger-menu
        $('.hamburger-menu, .menu ul li a').on('click', function () {
            $('.hamburger-menu .line-top, .menu_wrapper, header').toggleClass('current');
            $('.hamburger-menu .line-center').toggleClass('current');
            $('.hamburger-menu .line-bottom').toggleClass('current');
        });

        $('.menu li .nav-link, .link, .about_content p a').bind('click', function (event: MouseEvent) {
            // @ts-ignore
            const $anchor = $(this);
            const headerH = $('header').height();
            $('html, body')
                .stop()
                .animate(
                    {
                        scrollTop: $($anchor.attr('href')).offset().top - headerH + 'px',
                    },
                    600,
                    'easeInSine'
                );
            event.preventDefault();
        });
    }

    runAfterJquery(run);
}

function runAfterJquery(method: ($: any) => void) {
    // @ts-ignore
    if (window.jQuery) {
        // @ts-ignore
        method(window.jQuery);
    } else {
        setTimeout(function () {
            runAfterJquery(method);
        }, 50);
    }
}

export async function getStaticProps({ locale }: GetStaticPropsContext): Promise<GetStaticPropsResult<SSRConfig>> {
    return {
        props: {
            ...(await serverSideTranslations(locale || DEFAULT_LOCALE, tNamespaces)),
        },
    };
}

export default withClientAllowForGuestOnly(withTranslation(tNamespaces)(Page));
